import React from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs, USER_TYPES } from '../../../util/userHelpers';

import { Form, PrimaryButton, FieldTextInput, NamedLink, CustomExtendedDataField, FieldCheckboxGroup, Heading, FieldLocationAutocompleteInput, FieldSelect } from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';

import css from './SignupForm.module.css';
import { prepareToState } from '../../../util/urlHelpers';
import { findValues } from '../../../util/fieldHelpers';

const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
        fromState,
        isIframe,
      } = formRenderProps;

      const { userType } = values || {};
      console.log("userType", userType)
      console.log("preselectedUserType", preselectedUserType)

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const addressLabel = intl.formatMessage({ id: 'TeamPage.teamAddressLabel' });
      const addressPlaceholder= intl.formatMessage({ id: 'TeamPage.teamAddressPlaceholder' });

      // Custom user fields. Since user types are not supported here,
      // only fields with no user type id limitation are selected.
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);

      const noUserTypes = !userType && !(userTypes?.length > 0);
      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      let toState = prepareToState(fromState, isIframe);

      const link = <NamedLink name="LoginPage" className={css.logInLink} {...toState}>
          <FormattedMessage id="SignupForm.logInLinkText" />
        </NamedLink>;

      const isProvider = preselectedUserType == USER_TYPES.PROVIDER;

      const identity = v => v;

      const handleSetAddress = (fValues) => {
        let { context = [], address, text, place_name } = fValues?.prediction || {};
        let { origin } = fValues?.place || {};
        let street1 = `${address} ${text}`;

        if (!place_name.startsWith(street1)) {
          const streetFromPlaceNameItems = place_name.split(',')[0];
          if (streetFromPlaceNameItems.length) {
            street1 = streetFromPlaceNameItems[0];
          }
        }

        let zip = findValues(context, 'postcode');
        let city = findValues(context, 'place');
        let state = findValues(context, 'region');
        let country = findValues(context, 'country', 'short_code', true);

        let payload = {
          storedAddress: {
            place_name,
            origin
          },
          shippoAddress: {
            street1,
            zip,
            city,
            state,
            country
          }
        };
        props.processAddress(payload);
      };

      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingDeliveryForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingDeliveryForm.addressNotRecognized',
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldSelectUserType
            name="userType"
            userTypes={userTypes}
            hasExistingUserType={!!preselectedUserType}
            intl={intl}
          />

          {showDefaultUserFields ? (
            <div className={css.defaultUserFields}>
              {isProvider ? <FieldSelect
                className={css.select}
                name="sellerType"
                id="sellerType"
                label={intl.formatMessage({ id: 'SignupForm.sellerType' })}
                validate={validators.required(
                  intl.formatMessage({ id: 'SignupForm.sellerTypeRequired' })
                )}
              >
                <option hidden value="">
                  {intl.formatMessage({ id: 'SignupForm.sellerTypePlaceholder' })}
                </option>
                <option value="owner">
                  {intl.formatMessage({ id: 'SignupForm.sellerTypeOwner' })}
                </option>
                <option value="insider">
                  {intl.formatMessage({ id: 'SignupForm.sellerTypeInsider' })}
                </option>
                <option value="innovator">
                  {intl.formatMessage({ id: 'SignupForm.sellerTypeInnovator' })}
                </option>
              </FieldSelect> : null}
              {isProvider ? <Heading as="h4">
                <FormattedMessage id="TeamPage.personalInformation" />
              </Heading> : null}
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.firstNameLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.lastNameLabel',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              {/* <UserFieldDisplayName
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              /> */}

              <FieldTextInput
                type="tel"
                className={css.text}
                id={formId ? `${formId}.phone` : 'phone'}
                name="phone"
                autoComplete="phone"
                // label={intl.formatMessage({
                //   id: 'SignupForm.phone',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.phonePlaceholder',
                })}
                validate={validators.composeValidators(
                  validators.required(intl.formatMessage({ id: 'SignupForm.phoneRequired' })),
                  validators.validPhone(intl.formatMessage({ id: 'SignupForm.validPhone' })),
                )}
              />

              <FieldTextInput
                type="email"
                className={css.email}
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                // label={intl.formatMessage({
                //   id: 'SignupForm.emailLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                // label={intl.formatMessage({
                //   id: 'SignupForm.passwordLabel',
                // })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />

              {isProvider ? <>
                <Heading as="h4">
                  <FormattedMessage id="TeamPage.businessInformation" />
                </Heading>
                <FieldTextInput
                  className={css.text}
                  type="text"
                  id="businessName"
                  name="businessName"
                  placeholder={intl.formatMessage({ id: 'TeamPage.businessName' })}
                  validate={validators.required(intl.formatMessage({ id: 'TeamPage.businessNameRequired' }))}
                />
                <FieldTextInput
                  className={css.text}
                  type="tel"
                  id="businessPrimaryPhone"
                  name="businessPrimaryPhone"
                  placeholder={intl.formatMessage({ id: 'TeamPage.businessPrimaryPhone' })}
                  validate={validators.composeValidators(
                    validators.required(intl.formatMessage({ id: 'TeamPage.businessPrimaryPhoneRequired' })),
                    validators.validPhone(intl.formatMessage({ id: 'SignupForm.validPhone' }))
                  )}
                />
                <FieldTextInput
                  className={css.text}
                  type="text"
                  id="businessWebsite"
                  name="businessWebsite"
                  placeholder={intl.formatMessage({ id: 'TeamPage.businessWebsite' })}
                  validate={validators.required(intl.formatMessage({ id: 'TeamPage.businessWebsiteRequired' }))}
                />

                <FieldLocationAutocompleteInput
                  onSetAddress={handleSetAddress}
                  rootClassName={css.text}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  autoFocus={false}
                  name="businessLocation"
                  // label={addressLabel}
                  placeholder={addressPlaceholder}
                  useDefaultPredictions={false}
                  format={identity}
                  // valueFromForm={values.businessLocation}
                  validate={
                    validators.composeValidators(
                        validators.autocompleteSearchRequired(addressRequiredMessage),
                        validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                      )
                  }
                  hideErrorMessage={false}
                  // Whatever parameters are being used to calculate
                  // the validation function need to be combined in such
                  // a way that, when they change, this key prop
                  // changes, thus reregistering this field (and its
                  // validation function) with Final Form.
                  // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
                  key="locationValidation"
                />

                <Heading as="h4">
                  <FormattedMessage id="TeamPage.inventoryInformation" />
                </Heading>

                <FieldTextInput
                  className={css.text}
                  type="number"
                  id="expectedItems"
                  name="expectedItems"
                  placeholder={intl.formatMessage({ id: 'TeamPage.expectedItems' })}
                  validate={validators.required(intl.formatMessage({ id: 'TeamPage.expectedItemsRequired' }))}
                />

                <p className={css.text}>{intl.formatMessage({ id: 'TeamPage.equipmentSports' })}</p>

                <FieldCheckboxGroup
                  className={css.text}
                  name="sports"
                  id="sports"
                  optionLabelClassName={css.finePrint}
                  options={[
                    { key: 'bow-hunting', label: 'Bow Hunting' },
                    { key: 'gun-hunting', label: 'Gun Hunting' },
                    { key: 'fishing', label: 'Fishing' },
                    { key: 'backcountry-camping', label: 'Backcountry Camping' },
                    { key: 'other', label: 'Other' },
                  ]}
                />

                <FieldTextInput
                  className={css.text}
                  type="text"
                  id="sportsAdditionalData"
                  name="sportsAdditionalData"
                  placeholder={intl.formatMessage({ id: 'TeamPage.sportsAdditionalData' })}
                />

                <p className={css.text}>{intl.formatMessage({ id: 'TeamPage.equipmentConditions' })}</p>

                <FieldCheckboxGroup
                  className={css.text}
                  name="conditions"
                  id="conditions"
                  optionLabelClassName={css.finePrint}
                  options={[
                    { key: 'new', label: 'New' },
                    { key: 'sale', label: 'Sale / discount / closeout' },
                    { key: 'store-returns', label: 'Store returns' },
                    { key: 'consignment', label: 'Consignment' },
                    { key: 'rental', label: 'Rental' },
                    { key: 'other', label: 'Other' },
                  ]}
                />

                <Heading as="h4">
                  <FormattedMessage id="TeamPage.firstReference" />
                </Heading>

                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.frfname` : 'frfname'}
                    name="frfname"
                    autoComplete="frfname"
                    placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' })}
                    validate={validators.required(intl.formatMessage({ id: 'SignupForm.firstNameRequired' }))}
                  />

                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.frlname` : 'frlname'}
                    name="frlname"
                    autoComplete="frlname"
                    placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' })}
                    validate={validators.required(intl.formatMessage({ id: 'SignupForm.lastNameRequired' }))}
                  />
                </div>

                <FieldTextInput
                  className={css.text}
                  type="text"
                  id="frBusinessRelationship"
                  name="frBusinessRelationship"
                  placeholder={intl.formatMessage({ id: 'TeamPage.businessRelationship' })}
                  validate={validators.required(intl.formatMessage({ id: 'TeamPage.businessRelationshipRequired' }))}
                />

                <FieldTextInput
                  className={css.text}
                  type="tel"
                  id="frPhone"
                  name="frPhone"
                  placeholder={intl.formatMessage({ id: 'TeamPage.referencePhone' })}
                  validate={validators.composeValidators(
                    validators.required(intl.formatMessage({ id: 'TeamPage.referencePhoneRequired' })),
                    validators.validPhone(intl.formatMessage({ id: 'SignupForm.validPhone' }))
                  )}
                />

                <FieldTextInput
                  className={css.text}
                  type="email"
                  id="frEmail"
                  name="frEmail"
                  placeholder={intl.formatMessage({ id: 'TeamPage.referenceEmail' })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />

                <Heading as="h4">
                  <FormattedMessage id="TeamPage.secondReference" />
                </Heading>

                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.srfname` : 'srfname'}
                    name="srfname"
                    autoComplete="srfname"
                    placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' })}
                    validate={validators.required(intl.formatMessage({ id: 'SignupForm.firstNameRequired' }))}
                  />

                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.srlname` : 'srlname'}
                    name="srlname"
                    autoComplete="srlname"
                    placeholder={intl.formatMessage({ id: 'SignupForm.firstNamePlaceholder' })}
                    validate={validators.required(intl.formatMessage({ id: 'SignupForm.lastNameRequired' }))}
                  />
                </div>

                <FieldTextInput
                  className={css.text}
                  type="text"
                  id="srBusinessRelationship"
                  name="srBusinessRelationship"
                  placeholder={intl.formatMessage({ id: 'TeamPage.businessRelationship' })}
                  validate={validators.required(intl.formatMessage({ id: 'TeamPage.businessRelationshipRequired' }))}
                />

                <FieldTextInput
                  className={css.text}
                  type="tel"
                  id="srPhone"
                  name="srPhone"
                  placeholder={intl.formatMessage({ id: 'TeamPage.referencePhone' })}
                  validate={validators.composeValidators(
                    validators.required(intl.formatMessage({ id: 'TeamPage.referencePhoneRequired' })),
                    validators.validPhone(intl.formatMessage({ id: 'SignupForm.validPhone' }))
                  )}
                />

                <FieldTextInput
                  className={css.text}
                  type="email"
                  id="srEmail"
                  name="srEmail"
                  placeholder={intl.formatMessage({ id: 'TeamPage.referenceEmail' })}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </> : null}

              {/* <UserFieldPhoneNumber
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              /> */}
            </div>
          ) : null}

          {showCustomUserFields ? (
            <div className={css.customFields}>
              {userFieldProps.map(fieldProps => (
                <CustomExtendedDataField {...fieldProps} formId={formId} />
              ))}
            </div>
          ) : null}

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id={isProvider ? 'SignupForm.apply' : 'SignupForm.signUp'} />
            </PrimaryButton>
            <p className={css.logInText}>
              <FormattedMessage id="SignupForm.existingAccountText" values={{ link: link }}/>
            </p>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
